import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { useMemo } from "react";
import { env } from "../config";

const createApolloClient = (): any => {
  const restLink = new RestLink({
    uri: env.restApiUrl,
    // eslint-disable-next-line no-useless-escape
    fieldNameNormalizer: (fieldName) => fieldName.replace(/[\:]/g, ""),
  });

  const authRestLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: any) => {
      const UserGUID = localStorage.getItem("UserGUID");
      return {
        headers: {
          ...headers,
          UserGUID: UserGUID || "",
          // eslint-disable-next-line
          "Content-Type": "application/json",
        },
      };
    });
    return forward(operation);
  });

  const client = new ApolloClient({
    //concat restLink and httpLink
    link: ApolloLink.from([authRestLink, restLink]),
    cache: new InMemoryCache({
      typePolicies: {},
    }),
  });
  return client;
};

export function useApolloClient() {
  const client = useMemo(() => {
    return createApolloClient();
  }, []);
  return client;
}
